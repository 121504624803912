import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "../../../shared-ui/themes/igloonet"

import {
  TitleH2Center,
  PaddingHalfWrapper,
  PaddingWrapper,
  Content,
  Link,
  ContactFormJobs,
} from "@igloonet-web/shared-ui"

import Benefits from "../components/jobs/benefits"

import ProfileLukas from "../images/team/profile/lukas.jpg"
import PositionStamp from "../components/position-stamp"
import JobHeader from "../components/jobs/job-detail-header"

const ImgWrapper = styled.div`
  margin: auto;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    width: 70%;
  }

  img {
    display: block;
    margin: auto;
  }
`

const ImgTitle = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-weight: 500;
  margin: 10px;
  font-size: 22px;
`

const JobDetailMainWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  z-index: 1050;
`

const JobDetailWrapper = styled.div`
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  max-width: 944px;
  margin: 1.75rem auto;
  z-index: 2000;
  background: #ffffff;
`

const JobDetailWindow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`

const JobDetailBody = styled.div`
  position: relative;
`

const JobDetailContent = styled.div`
  display: block;
  margin: auto;
  line-height: 1.5;
  max-width: 690px;
`

const FacebookAdsSpecialistaProjektovyManazerJobDetail = () => {
  return (
    <ThemeProvider theme={theme}>
      <JobDetailWindow />
      <JobDetailMainWrapper>
        <JobDetailWrapper>
          <JobHeader />

          <JobDetailBody>
            <PositionStamp />
            <JobDetailContent className="px-2 px-lg-0">
              <PaddingWrapper>
                <h1 className="d-block my-auto pb-5">
                  Facebook Ads specialista | projektový manažer
                </h1>
                <p>
                  <strong>
                    Ahoj, jsme <Link to="//igloonet.cz/">igloonet</Link>
                  </strong>{" "}
                  a pod jednou střechou pomáháme zákazníkům jak s vývojem
                  e-shopů a webů, tak s hostingem a on-line marketingem. U něj
                  máme v malíku samotnou strategii a propagaci, ale také rozvoj
                  interních týmů, mentoring nebo konzultace.
                </p>
                <p>
                  Do zkušeného týmu nyní hledáme{" "}
                  <strong>
                    specialistu na reklamu na sítích, který by nám současně
                    pomohl s vedením
                  </strong>{" "}
                  klientských a klidně také interních <strong>projektů</strong>.
                </p>
                <p>
                  Ani v jedné z rolí se nemusíš bát desítek drobných zákazníků,
                  <strong>
                    {" "}
                    zaměřujeme se primárně na ty střední a větší
                  </strong>{" "}
                  (o některých si můžeš přečíst v{" "}
                  <Link to="/reference/">referencích</Link>). Hodit se ti proto
                  bude jak širší marketingový/byznysový nadhled, tak smysl pro
                  detail konkrétního případu.
                </p>
              </PaddingWrapper>

              <PaddingHalfWrapper>
                <TitleH2Center>
                  Zdokonaluj se v marketingu a rozvíjej své manažerské
                  schopnosti
                </TitleH2Center>
                <p>
                  Aby ti stereotyp nenasadil na oči klapky, můžeš se těšit na{" "}
                  <strong>co nejzajímavější různorodost práce</strong>. Není
                  výjimkou, že se u nás e-mailing specialista učí reklamě na
                  sítích a ppcčkař proniká třeba do BigQuery nebo výzkumu.
                </p>
                <p>
                  Pokud se tedy chceš dál{" "}
                  <strong>
                    zdokonalovat ve své odbornosti a v marketingu samotném a
                    současně rozvíjet manažerské a konzultantské schopnosti
                  </strong>{" "}
                  na zajímavých projektech, rozhodně čti dál!
                </p>
                <p>
                  <strong>
                    A čti dál, i kdyby ses na nějakou část role úplně necítil/a
                  </strong>
                  : vše doučíme. Průměrná zkušenost kolegů je 6+ let, budeš se
                  mít o koho opřít.
                </p>
                <Content>
                  <p>
                    <strong>Co bude tvůj denní chleba?</strong>
                  </p>
                  <ul>
                    <li>
                      Nastavení, správa a rozvoj kampaní na sociálních sítích –
                      primárně Facebook/Instagram, k tomu ale také třeba
                      LinkedIn nebo Pinterest
                    </li>
                    <li>Vyhodnocování, analýzy a reporting</li>
                    <li>
                      Pomoc klientům s obsahovou strategií a plánováním obsahu,
                      případně i jeho tvorbou, pokud tě to táhne tímhle směrem
                    </li>
                    <li>
                      Celý koloběh spojený s vedením projektů: od pravidelné
                      komunikace s klientem, nastavení očekávání, postupu práce
                      a obecně plánování přes samotné delegování a řízení
                      priorit, lidí či rozpočtů po celkovou kontrolu, hodnocení
                      projektu (s vedoucím marketingu) i kolegů, poučení a posun
                      klienta
                    </li>
                    <li>Vzdělávání a rozvoj sebe i druhých</li>
                  </ul>

                  <p>
                    <strong>Jak ti pomůžeme?</strong>
                  </p>
                  <ul>
                    <li>
                      Připravíme ti onboard plán na míru – ať už raději padáš
                      rovnou do vody a stačí ti jistící lano, nebo jdeš pomalu
                      ale jistě od břehu, support zkušených marketérů a
                      projekťáků máš jistý
                    </li>
                    <li>
                      V oboru jsme už 15 let, a věci tak neděláme na blind –
                      můžeš se proto opřít o naše ověřené postupy a procesy.
                      Budeme ale rádi, pokud přineseš vlastní pohled na věc
                    </li>
                  </ul>
                  <p>
                    <strong>Naše požadavky?</strong>
                  </p>
                  <ul>
                    <li>
                      Reklamě na sítích, potažmo obsahu se věnuješ už nějaký ten
                      pátek. Dokážeš správně zadat nasazení měření, vymyslet, na
                      čem propagaci postavit, nastavit strukturu kampaní,
                      vytvořit reklamy, sledovat a vyhodnotit jejich výkon
                    </li>
                    <li>
                      Současně víš, že jde jen o součást širokého marketingového
                      mixu, nikoliv jedinou složku
                    </li>
                    <li>
                      Spíš než cestou nejužšího specialisty chceš dál sám vést
                      projekty, školit a rozvíjet klienty
                    </li>
                    <li>
                      Několik zdárně odvedených projektů už máš ideálně za
                      sebou, anebo jsi s jejich vedením pomáhal/a. Chápeš, že
                      bez projektového systému se daleko nedostaneš
                    </li>
                    <li>
                      Dokážeš klientovi vysvětlit, proč se mu jeho současná
                      strategie nevyplácí
                    </li>
                    <li>
                      Velkou výhodou je, pokud rovnou vidíš chyby v: byli by
                      jste, výjímečný, tamnější, defakto a eshop. A taky pokud
                      ti nechybí estetické-grafické cítění :)
                    </li>
                  </ul>
                  <p>
                    <strong>Naše sny:</strong>
                  </p>
                  <ul>
                    <li>
                      Opravdu se nebojíš komunikovat, umíš proaktivně objevit
                      potřeby klienta, které nemusí být na první dobrou patrné,
                      nadchnout ho pro dané řešení
                    </li>
                    <li>
                      Máš za sebou aspoň desítku odvedených školení a workshopů
                    </li>
                    <li>Už ses párkrát setkal s nasazením conversion API</li>
                    <li>Můžeme tě nazvat mistrem češtiny :)</li>
                  </ul>
                </Content>
              </PaddingHalfWrapper>

              <TitleH2Center>Kdo tě povede?</TitleH2Center>
              <ImgWrapper>
                <>
                  <img
                    src={ProfileLukas}
                    alt=""
                    width="270"
                    height="270"
                    loading="lazy"
                  />
                </>
                <ImgTitle>Lukáš</ImgTitle>
                <p className="pt-2">
                  Lukáš se na vedoucího marketingu vypracoval od stážisty a
                  specialisty na sociální sítě přes garanta sítí, vedoucího
                  projektů a nakonec i teamleadera. Části rolí se věnuje v menší
                  míře i dál. Dokáže se proto dobře vžít do situace jednotlivých
                  kolegů a pomoci jim v hledání správných řešení.
                </p>
                <p>
                  Podílí se na rozvoji onboard procesu, osobně proto dohlédne,
                  že tvé nalodění proběhne bez problému a budeš v týmu co
                  nejspokojenější.
                </p>
              </ImgWrapper>

              <PaddingHalfWrapper>
                <Content>
                  <TitleH2Center>Co ti nabízíme?</TitleH2Center>
                  <ul>
                    <li>
                      Finanční odměnu, na které se domluvíme dle tvých
                      dosavadních zkušeností
                    </li>
                    <li>
                      Rozvoj v tvé současné odbornosti a manažerských
                      dovednostech, ale také příležitost rozšířit si přehled v
                      dalších oblastech marketingu
                    </li>
                    <li>
                      Svobodu v organizaci časoprostoru a práce – pravidelný,
                      ale nikoliv nucený, homeoffice jsme měli dříve, než to
                      bylo nutné :)
                    </li>
                    <li>Přátelské a neformální prostředí</li>
                    <li>Vývojáře a hosting jen pár schodů daleko</li>
                    <li>
                      Spousty benefitů včetně sickdays, vlastního parkovacího
                      místa nebo neustále plné ledničky ↓
                    </li>
                  </ul>
                </Content>
              </PaddingHalfWrapper>

              <PaddingHalfWrapper>
                <TitleH2Center>Co dostaneš za benefity?</TitleH2Center>
                <Benefits />
              </PaddingHalfWrapper>
            </JobDetailContent>

            <ContactFormJobs heading="Napiš Lukášovi a zbytečně to neodkládej" />
          </JobDetailBody>
        </JobDetailWrapper>
      </JobDetailMainWrapper>
    </ThemeProvider>
  )
}

export default FacebookAdsSpecialistaProjektovyManazerJobDetail
